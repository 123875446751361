import _ from 'underscore';

import left_menu_api from '@/component/admin-layout/component/left-menu/api';

export default {
    data() {
        return {};
    },
    computed: {
        entity_list() {
            const __this = this;
            return __this.$store.state.data_for_breadcrumb.entity_list;
        },
    },
    methods: {
        __init() {
            const __this = this;
            const meta = __this.$route.meta;
            if (!meta) return;
            const entity_list_for_breadcrumb = meta.entity_list_for_breadcrumb;
            if (!entity_list_for_breadcrumb) return;
            if (!_.isArray(entity_list_for_breadcrumb)) return;
            __this.$store.state.data_for_breadcrumb.entity_list = entity_list_for_breadcrumb;
        },

        __btn_collapse_click() {
            const __this = this;
            left_menu_api.collapse();
        },
    },
    watch: {
        $route(pNewValue, pOldValue) {
            const __this = this;
            __this.__init();
        }
    },
    created() {
        const __this = this;
        __this.__init();
    },
}
