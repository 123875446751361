import Q from 'q';
import _ from 'underscore';
import store from '@/lib/store';

const remove_tag_by_index = function (pParameter) {
    if (!pParameter) pParameter = {};
    const index = pParameter.index;

    const tag_list = store.state.data_for_history_tag.tag_list;
    tag_list.splice(index, 1);
};

const remove_tag_by_route = function (pParameter) {
    if (!pParameter) pParameter = {};
    const route = pParameter.route;

    const tag_list = store.state.data_for_history_tag.tag_list;

    const index = _.chain(tag_list)
        .findIndex(function (m) {
            return m.route.fullPath === route.fullPath;
        })
        .value()
    ;
    if (index === -1) {
        const msg = 'remove_tag_by_route parameter error, code=001';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    remove_tag_by_index({
        index: index,
    });
};

const remove_all_tag = function () {
    store.state.data_for_history_tag.tag_list = [];
};

const modify_tag_name_by_route = function (pParameter) {
    if (!pParameter) pParameter = {};
    const route = pParameter.route;
    const tag_name = pParameter.tag_name;

    const tag_list = store.state.data_for_history_tag.tag_list;

    const query = _.chain(tag_list)
        .find(function (m) {
            return m.route.fullPath === route.fullPath;
        })
        .value()
    ;
    if (!query) {
        const msg = 'modify_tag_name_by_route parameter error, code=001';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    query.name = tag_name;
};

export default {
    remove_tag_by_index,
    remove_tag_by_route,
    remove_all_tag,
    modify_tag_name_by_route,
};
