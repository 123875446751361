import Breadcrumb from './component/breadcrumb/index.vue';
import CurrentUserMenu from './component/current-user-menu/index.vue';
import HistoryTag from './component/history-tag/index.vue';
import LeftMenu from './component/left-menu/index.vue';

export default {
  components: {
    Breadcrumb,
    CurrentUserMenu,
    HistoryTag,
    LeftMenu,
  },
  data() {
    return {};
  },
}
