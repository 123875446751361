import extend from 'extend';
import _ from 'underscore';
import get_menu_data from './get_menu_data';
import all_routes_processor from '@/router/init_router/lib/all_routes_processor';

export default {
    data() {
        return {
            data_get_menu_data: {
                menu_list: [],
                all_menu_list: [],
            },
            menu_item_list: [],
            default_openeds: [],
            default_active: ``,
        };
    },
    computed: {
        is_open() {
            const __this = this;
            return __this.$store.state.data_for_left_menu.is_open;
        }
    },
    watch: {
        $route(pNewValue, pOldValue) {
            const __this = this;
            __this.__init_default_active();
        },
    },
    methods: {
        handleOpen(key, keyPath) {
            console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            console.log(key, keyPath);
        },

        __init_default_active() {
            const __this = this;

            const all_route_list = all_routes_processor.get_all_route_list();

            const __get_active_route_name = function () {
                let r = ``;

                const meta = __this.$route.meta;
                if (!meta) return r;

                const data_for_left_menu = meta.data_for_left_menu;
                if (!data_for_left_menu) return r;

                const get_active_route_name = data_for_left_menu.get_active_route_name;
                if (!_.isFunction(get_active_route_name)) return r;

                r = get_active_route_name({
                    all_route_list,
                });
                return r;
            };

            const active_route_name = __get_active_route_name();

            const route = _.chain(all_route_list)
                .find(function (m) {
                    return m.name === active_route_name;
                })
                .value()
            ;

            if (route) {
                //非一级页面
                __this.default_active = route.path;
            } else {
                //一级页面
                __this.default_active = __this.$route.path;
            }
        },
    },
    created() {
        const __this = this;

        __this.data_get_menu_data = get_menu_data({
            $router: __this.$router,
        });

        // __this.default_openeds = _.chain(__this.data_get_menu_data.menu_list)
        //     .map(function (m, i) {
        //         return i.toString();
        //     })
        //     .value()
        // ;

        __this.__init_default_active();
    },
}
