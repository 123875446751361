const _ = require(`underscore`);

const __all_menu_list = [
    //<editor-fold desc="示例功能">
    {
        id: `0`,
        parent_id: ``,
        title: `示例功能`,
        route_name: ``,
        level: 1,
    },
    {
        id: `0.1`,
        parent_id: `0`,
        title: `主页`,
        route_name: `admin-home`,
        level: 2,
    },
    {
        id: `0.2`,
        parent_id: `0`,
        title: `桌面`,
        route_name: `admin-desktop`,
        level: 2,
    },
    {
        id: `0.3`,
        parent_id: `0`,
        title: `通讯录`,
        route_name: `admin-contacts`,
        level: 2,
    },
    {
        id: `0.4`,
        parent_id: `0`,
        title: `示例数据`,
        route_name: `admin-sample-data-list`,
        level: 2,
    },
    {
        id: `0.5`,
        parent_id: `0`,
        title: `v-model-test`,
        route_name: `admin-v-model-test`,
        level: 2,
    },
    {
        id: `0.6`,
        parent_id: `0`,
        title: `v-for-test`,
        route_name: `admin-v-for-test`,
        level: 2,
    },
    {
        id: `0.7`,
        parent_id: `0`,
        title: `vuex-test`,
        route_name: `admin-vuex-test`,
        level: 2,
    },
    {
        id: `0.8`,
        parent_id: `0`,
        title: `三级菜单示例`,
        route_name: ``,
        level: 2,
    },
    {
        id: `0.8.1`,
        parent_id: `0.8`,
        title: `3.1`,
        route_name: `admin-multi-level-menu-test-3-1`,
        level: 3,
    },
    {
        id: `0.8.2`,
        parent_id: `0.8`,
        title: `3.2`,
        route_name: `admin-multi-level-menu-test-3-2`,
        level: 3,
    },
    {
        id: `0.9`,
        parent_id: `0`,
        title: `global-query-test`,
        route_name: `admin-global-query-test-page-001`,
        level: 2,
    },
    {
        id: `0.10`,
        parent_id: `0`,
        title: `component-custom-slot`,
        route_name: `admin-component-custom-slot`,
        level: 2,
    },
    //</editor-fold>

    //<editor-fold desc="mocha">
    {
        id: `1`,
        parent_id: ``,
        title: `mocha`,
        route_name: ``,
        level: 1,
    },
    {
        id: `1.1`,
        parent_id: `1`,
        title: `主页`,
        route_name: `admin-mocha-home`,
        level: 2,
    },
    {
        id: `1.2`,
        parent_id: `1`,
        title: `release`,
        route_name: `admin-mocha-core`,
        route_query: {
            grep: `release`,
        },
        level: 2,
    },
    {
        id: `1.3`,
        parent_id: `1`,
        title: `beta`,
        route_name: `admin-mocha-core`,
        route_query: {
            grep: `beta`,
        },
        level: 2,
    },
    {
        id: `1.4`,
        parent_id: `1`,
        title: `alpha`,
        route_name: `admin-mocha-core`,
        route_query: {
            grep: `alpha`,
        },
        level: 2,
    },
    //</editor-fold>
];

const all_menu_list = _.chain(__all_menu_list)
    // .filter(function (m) {
    //     return 1 === 1
    //         && m.id !== `0`
    //         && m.parent_id !== `0`
    // })
        .value()
;

module.exports = all_menu_list;
