import _ from 'underscore';
import api from './api';

export default {
    data() {
        return {};
    },
    computed: {
        tag_list() {
            const __this = this;
            return __this.$store.state.data_for_history_tag.tag_list;
        },
    },
    methods: {
        __add_tag() {
            const __this = this;
            const tag_list = __this.tag_list;

            const query = _.chain(tag_list)
                .find(function (m) {
                    return m.route.fullPath === __this.$route.fullPath;
                })
                .value()
            ;
            if (!query) {
                _.each(tag_list, function (m) {
                    m.type = `info`;
                });

                let name = ``;
                if (__this.$route.meta.entity_for_history_tag) {
                    if (__this.$route.meta.entity_for_history_tag.name) {
                        name = __this.$route.meta.entity_for_history_tag.name;
                    }
                }

                const tag = {};
                tag.route = __this.$route;
                tag.type = ``;
                tag.name = name;
                tag_list.push(tag);

                //向最新的一个滚动
                __this.$nextTick(function () {
                    const dom_history_tag = __this.$refs.dom_history_tag;
                    if (!dom_history_tag) return;
                    dom_history_tag.scrollTo({
                        left: dom_history_tag.scrollWidth,
                    });
                });
            }

            if (query) {
                _.each(tag_list, function (m) {
                    m.type = `info`;
                });

                query.type = ``;
            }

        },

        __btn_tag_close_click(pTag, pIndex) {
            const __this = this;
            const tag_list = __this.tag_list;

            api.remove_tag_by_index({
                index: pIndex,
            });

            if (pTag.type === ``) {
                //如果关闭的是当前激活的tag
                if (tag_list.length > 0) {
                    const last_tag = tag_list[tag_list.length - 1];
                    last_tag.type = ``;
                    __this.$router.push(last_tag.route);
                }
            }
        },
        __btn_tag_click(pTag, pIndex) {
            const __this = this;
            const tag_list = __this.tag_list;

            _.each(tag_list, function (m) {
                m.type = `info`;
            });
            pTag.type = ``;

            __this.$router.push(pTag.route);
        },

        __closable(pTag, pIndex) {
            const __this = this;
            const tag_list = __this.tag_list;

            let flag = true;
            if (tag_list.length <= 1) {
                flag = false;
            }
            return flag;
        },
    },
    watch: {
        $route(pNewValue, pOldValue) {
            const __this = this;
            __this.__add_tag();
        },

    },
    created() {
        const __this = this;
        __this.__add_tag();
    },
}
